import React, { useEffect, useState } from 'react'

import './LoadingHandler.sass'

const LoadingHandler = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoadingHandler
