// import styles from '../styles/index.module.css'
import React, { Component, useCallback, useState } from 'react'
import './PhotoResult.sass'

import {
  BsFillHandThumbsDownFill,
  BsFillHandThumbsUpFill
} from 'react-icons/bs'

interface Props {
  photoUrl?: string
  blobImage?: any
  confirmCapture: (data: any) => void
}

const PhotoResult = ({ photoUrl, blobImage, confirmCapture }: Props) => {
  const [controllers, setControllers] = useState(false)

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  if (photoUrl) {
    setTimeout(() => {
      setControllers(true)
    }, 3000)
  }
  return (
    <div className="preview-container">
      <h2>A foto ficou boa?</h2>
      <div className={`preview-controll ${controllers ? 'active' : ''}`}>
        <a
          href="#"
          onClick={() => window.location.reload()}
          className="controllers control-back"
        >
          <span>
            <BsFillHandThumbsDownFill />
          </span>
        </a>
        <a
          href="#"
          onClick={async () => confirmCapture(blobImage)}
          className="controllers control-forward"
        >
          <span>
            <BsFillHandThumbsUpFill />
          </span>
        </a>
      </div>
      <img alt="Sorria!" src={photoUrl} />
    </div>
  )
}

export default PhotoResult
