import React, { Component, useCallback, useState } from 'react'
import type {
  FaceCallback,
  FaceComponentData
} from '@innovatrics/dot-face-auto-capture'
import FaceAutoCapture from './components/FaceAutoCapture'
import PhotoResult from './components/PhotoResult'
import styles from './styles/index.module.css'

import { BASE } from '../../basedata/constants'

interface Props {
  onResponse: (response: string) => void
  token: (token: string) => void
}

function BiometricComponent({ onResponse, token }: Props) {
  const [photoUrl, setPhotoUrl] = useState<string>()

  const [userToken, setUserToken] = React.useState('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const [blobImage, setBlobImage] = React.useState({})

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const handlePhotoTaken = (image: Blob, data: FaceComponentData) => {
    const imageUrl = URL.createObjectURL(image)
    setBlobImage(image)
    setPhotoUrl(imageUrl)
  }

  const handleFaceCapturePhotoTaken: FaceCallback = (image, data) => {
    // onResponse('loading')
    handlePhotoTaken(image, data)
  }

  const confirmCapture = (image: any) => {
    onResponse('loading')
    handleFaceCreate(image)
  }

  const handleFaceCreate = async (image: Blob) => {
    const newImage = await blobToBase64(image)

    fetch(`${BASE.api.base_url}${BASE.api.dis_proccess}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        face: newImage
      })
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setTimeout(() => {
            onResponse('invalid')
          }, 500)
          return false
        }

        if (response.dis < 0.9) {
          setTimeout(() => {
            onResponse('invalid')
          }, 500)
          return false
        }

        const croppedFace = response.FACEcrop.data

        const currentToken = window.sessionStorage.getItem('originCompany')

        // save image created
        fetch(`${BASE.api.base_url}${BASE.api.create_face}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: BASE.api.token
          },
          body: JSON.stringify({
            userId: 'xxxx',
            originToken: userToken,
            photoURL: 'xxxx',
            partnerName: 'bpfaceId',
            imageBase64: newImage,
            imageBase64cropped: croppedFace,
            originCompany: currentToken,
            originCompanyId: currentToken
          })
        })
          .then((resg) => resg.json())
          .then((responseSave) => {
            if (
              responseSave.userInformation &&
              responseSave.userInformation.callbackURL
            ) {
              sessionStorage.setItem(
                'callbackurl',
                responseSave.userInformation.callbackURL
              )
            }

            console.log('-->')
            console.log(responseSave)

            if (responseSave.status === 'userNotFound') {

              return onResponse('invalid')

            }
            setTimeout(() => {
              onResponse('success')
            }, 2000)
          })
          .catch((error) => {
            console.log(error)
            setTimeout(() => {
              onResponse('error')
            }, 2000)
          })
      })
      .catch((error) => {
        console.log('===> ')
        console.log(error)
        console.log('= = = = ')
        setTimeout(() => {
          onResponse('invalid')
        }, 2000)
      })
  }

  const handleError = useCallback((error: Error) => {
    console.log(error)
    setUserHasCamera(false)
    // onResponse('error')
  }, [])

  React.useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      setUserToken(window.sessionStorage.getItem('token') + '')
    }
  }, [])

  return (
    <>
      {userHasCamera ? (
        <>
          {' '}
          <h1>
            <span>Vamos lá?!</span>
          </h1>
          <hr />
          <div className={`${styles.app} medium-frame`}>
            <FaceAutoCapture
              onPhotoTaken={handleFaceCapturePhotoTaken}
              onError={handleError}
            />
            {photoUrl && (
              <PhotoResult
                confirmCapture={confirmCapture}
                photoUrl={photoUrl}
                blobImage={blobImage}
              />
            )}
          </div>
        </>
      ) : (
        <div className="no-camera">
          <h1>
            Não achamos <span>sua câmera</span>
          </h1>
          <p>
            Não conseguimos acessar sua câmera para prosseguir. <br />
            Por favor, revise as permissões do seu aplicativo ou tente com outro
            celular
          </p>
          <div className="form-group form-submit">
            <a
              className="btn btn-primary form-control form-control-lg"
              onClick={() => window.location.reload()}
            >
              Recarregar
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default BiometricComponent

import reportWebVitals from './webvitals'

reportWebVitals()
