import React, { Component, useCallback, useEffect, useState } from 'react'
import './Biometric/OnboardCard.sass'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'

interface Props {
  onResponse: (response: any) => void
}

function Welcome({ onResponse }: Props) {

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm()
  
  const [photoState, setphotoState] = useState<string>('')

  const [showTerms, setShowTerms] = useState(false)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  const onSubmit = (data: any) => {
    onResponse({ step: 'signup' })
    console.log(data)
  }

  return (
    <div className={`${photoState} card`}>
 {showTerms ? (
<>
<p>
        O cadastro de seus dados pessoais e dados pessoais sensíveis em nossa plataforma é indispensável para a prestação do serviço que propomos, justificado pelo art. 7º, V e 11º, II, d) da Lei Geral de Proteção de Dados Pessoais.<br/><br/>
        Para prosseguir com a compra de ingresso precisamos que você realize o cadastro de biometria facial.<br/><br/>
        A Biometria Facial é uma tecnologia que identifica os pontos biométricos de um rosto, individualizando e facilitando a identificação de cada titular. Por isso, é importante dizer que utilizamos um sistema automatizado que, de maneira alguma, considera sua etnia, cor, orientação sexual, religiosa, política, social ou faz qualquer juízo de valor sobre você.<br/><br/>
        Para obter mais informações sobre a forma do seu tratamento de dados pessoais, cheque nossa política de privacidade
      </p>
            <div className="form-group form-submit">
            <a
              className="btn btn-primary form-control form-control-lg"
              onClick={() => setShowTerms(false)}
            >
              Voltar
            </a>
          </div></>

 ):(


      
      <>
      <h1>
        Seja <span>bem-vindo!</span>
      </h1>
      <hr />
      <p className="text-center">
        Obrigado por participar desta transformação tão grande para a nossa
        instituição! Em poucas etapas, vamos te ajudar a cadastrar sua biometria
        facial para acessar o clube de uma forma mais fácil, rápida e
        tecnológica!
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
            
            <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input form-control-sm"
                  {...register('valid', { required: true })}
                  id="valid"
                />
                <label className="form-check-label" htmlFor="valid">
                  Ao continuar, você estará concordando com o <a href='#' onClick={(event) => {event?.preventDefault(); setShowTerms(true)}}>aviso de privacidade</a> e nossa  <a href="https://sep-bucket-prod.s3.amazonaws.com/wp-content/uploads/2021/08/politica-de-privacidade_12082021.pdf" rel="noreferrer" target="_blank">
                    Política de Privacidade</a>
                </label>
                {errors.valid && (
                  <span className="label-error">
                    Você precisa aceitar os termos de uso
                  </span>
                )}
              </div>
            <div className="form-group form-submit">
              {/* <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => onResponse({ step: 'faceproceed' })}
              >
             
              </a> */}
              <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="   Vamos lá!"
              onClick={() => clearErrors()}
            />
            </div>
            </Form>

     

  
      </>
       )}
    </div>
  )
}


export default Welcome
