import React, { useEffect, useState } from 'react'

interface Props {
  onResponse: (response: any) => void
}

const ErrorGlassesHandler = ({ onResponse }: Props) => {
  return (
    <>
      <h1>
        <span>Ocorreu um </span>erro
      </h1>
      <hr />
      <p>
        Identificamos que você está usando alguma cobertura para os olhos
        (óculos, bandanas, faixas ou similares). Poderia retirar e tentar
        novamente por favor?
      </p>
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'faceproceed' })}
        >
          Refazer
        </a>
      </div>
    </>
  )
}

export default ErrorGlassesHandler
